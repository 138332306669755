/* eslint-disable @typescript-eslint/no-explicit-any */
import { Namespace, TFunction } from 'i18next';
import { Option } from '../components/Option';
import { Translations } from '../models/Translation';
import i18n from 'i18next';

/**
 * Groups all items in an array of objects `T` where the value of property `K` is the same
 * @param array Items to group
 * @param key Key of `T` to group by
 */
export function groupBy<T, K extends keyof T>(array: T[], key: K): Map<T[K], T[]> {
  const map = new Map<T[K], T[]>();
  array.forEach((item) => {
    const itemKey = item[key];
    if (!map.has(itemKey)) {
      map.set(
        itemKey,
        array.filter((i) => i[key] === item[key]),
      );
    }
  });
  return map;
}

export function deDuplicate<T, K extends keyof T>(array: T[], key: K): T[] {
  const grouped = groupBy(array, key);

  const items = [];
  for (const item of grouped.values()) {
    items.push(item[0]);
  }

  return items;
}

// https://stackoverflow.com/a/51514813
export const splitToChunks = <T>(arr: T[], parts: number): T[][] => {
  const array = [...arr];
  const colCount = Math.ceil(array.length / parts);
  const result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, colCount));
  }
  return result;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export const sortExpression = (field: string, direction: SortDirection): string => {
  return `${direction === SortDirection.Asc ? '+' : '-'}${field}`;
};

export const SortingOptions = (t: TFunction<Namespace>): Option<string, number>[] => {
  return [
    {
      id: SortDirection.Asc,
      text: t('common:list.sorting.ascending'),
      value: 1,
    },
    {
      id: SortDirection.Desc,
      text: t('common:list.sorting.descending'),
      value: -1,
    },
    {
      id: 'clear',
      text: t('common:list.sorting.clear'),
      value: 0,
    },
  ];
};

export type SortingKey<TArrItem> = {
  key: TArrItem extends { translations: Translations<infer TTranslationKeys> } ? TTranslationKeys : keyof TArrItem;
  direction: number;
};

export const SortArray = <TArrItem extends object>(inputArr: TArrItem[], sorting: SortingKey<TArrItem>): TArrItem[] => {
  return inputArr.sort((a: TArrItem, b: TArrItem) => {
    let aValue =
      (sorting.key in a ? (a as any)[sorting.key] : 'translations' in a ? (a?.translations as any)?.[i18n.language]?.[sorting.key] : '') || '0';
    let bValue =
      (sorting.key in b ? (b as any)[sorting.key] : 'translations' in b ? (b?.translations as any)?.[i18n.language]?.[sorting.key] : '') || '0';

    if (typeof aValue === 'string') {
      aValue = aValue.toLocaleLowerCase();
    }

    if (typeof bValue === 'string') {
      bValue = bValue.toLocaleLowerCase();
    }

    if (aValue === bValue) {
      return 0;
    }

    return (aValue > bValue ? 1 : -1) * sorting.direction;
  });
};

export function toRecord<T extends Record<string, any>, K extends keyof T>(array: T[], selector: K): Record<T[K], T> {
  return array.reduce((acc, item) => ({ ...acc, [item[selector]]: item }), {} as Record<T[K], T>);
}

export function toGroupedRecord<T extends Record<string, any>, K extends keyof T>(array: T[], selector: K): Record<T[K], T[]> {
  return array.reduce(
    (acc, item) => {
      acc[item[selector]] ??= [];
      acc[item[selector]].push(item);

      return acc;
    },
    {} as Record<T[K], T[]>,
  );
}

export function recordMap<T extends Record<string, any>, TKey extends keyof T, TValue extends T[TKey], TReturn>(
  array: T,
  mapper: (key: TKey, value: TValue) => TReturn,
): Record<TKey, TReturn> {
  const result = {} as Record<TKey, TReturn>;
  for (const [key, value] of Object.entries(array) as [TKey, TValue][]) {
    result[key] = mapper(key, value);
  }
  return result;
}

type GroupedRecord = Record<string, number>;

export function groupedRecord<T>(arr: T[], idProp: keyof T, customProp?: keyof T, increment?: number): GroupedRecord {
  const grouped: GroupedRecord = {};

  arr.forEach((item) => {
    const id = String(item[idProp]);
    const groupValue = (customProp ? Number(item[customProp]) : 1) + (increment || 0);

    if (grouped[id]) {
      grouped[id] = grouped[id] + groupValue;
    } else {
      grouped[id] = groupValue;
    }
  });

  return grouped;
}
